import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import * as styles from '../components/homeStyles.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import letyJuraidiniImg from '../images/lj.png';
import TextField from '@mui/material/TextField';

const IndexPage = () => {
  const initialFormData = {
    firstName: {
      value: '',
      label: 'Nombre',
      type: 'text',
      placeholer: 'Juán',
      autoComplete: 'given-name',
      error: '',
    },
    lastName: {
      value: '',
      label: 'Apellidos',
      type: 'text',
      placeholer: 'Pérez',
      autoComplete: 'family-name',
      error: '',
    },
    email: {
      value: '',
      label: 'Email',
      type: 'email',
      placeholer: 'lety@letyjuraidini.com',
      autoComplete: 'email',
      error: '',
    },
    phone: {
      value: '',
      label: 'Teléfono',
      type: 'tel',
      placeholer: '+525512345678',
      autoComplete: 'tel',
      error: '',
    },
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  };
  const isPhone = (str = '') => {
    return str?.match(/\d/g)?.join('')?.length === 10;
  };
  const handleChange = (field, event) => {
    let fieldToUpdate = formData[field];
    fieldToUpdate.value = event.target.value;
    setFormData({ ...formData, [field]: { ...fieldToUpdate } });
  };
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    let data = Object.entries(formData);
    let errors = {};
    for (let index = 0; index < data.length; index++) {
      let [field, content] = data[index];
      if (!content.value) {
        errors[field] = 'Este campo es obligatorio';
      }
      if (field === 'email') {
        if (!isEmail(content.value)) {
          errors[field] = 'Por favor agrega un correo válido';
        }
      }
      if (field === 'phone') {
        if (!isPhone(content.value)) {
          errors[field] = 'Por favor agrega un número de teléfono válido';
        }
      }
    }
    if (Object.entries(errors).length) {
      let newFormData = JSON.parse(JSON.stringify(formData));
      for (let index = 0; index < Object.entries(newFormData).length; index++) {
        let field = Object.entries(newFormData)[index][0];
        newFormData[field].error = errors[field];
      }
      setLoading(false);
      setFormData(newFormData);
    } else {
      const webHook = 'https://hook.us1.make.com/63mye5etdz6rskyjvkt9z600rybirhiq';
      fetch(webHook, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(formData),
      })
        .then(res => {
          if (res.ok) {
            setLoading(false);
            setSubmitted(true);
          }
        })
        .catch(error => console.error(error));
    }
  };

  return (
    <Layout>
      <Seo title="Regístrate al evento" />
      <img src={letyJuraidiniImg} className={styles.logo} />
      <h1 className={styles.pageTitle}>
        {submitted
          ? `¡Felicidades ${formData.firstName.value}!`
          : 'Regístrate aquí a la Masterclass GRATUITA "Relaciones Sanas empezando por mí"'}
      </h1>
      {!submitted ? (
        <form onSubmit={e => handleSubmit(e)} className={styles.form}>
          {Object.entries(formData).map((field, idx) => (
            <div key={idx} className={styles.inputContainer}>
              <TextField
                variant="outlined"
                label={field[1].label}
                classes={{ root: styles.input, error: styles.inputError }}
                InputProps={{
                  classes: { error: styles.inputError, focused: styles.inputFocus },
                }}
                required
                fullWidth
                name={field[0]}
                value={field[1].value}
                placeholder={field[1].placeholer}
                onChange={e => handleChange(field[0], e)}
                error={!!field[1].error}
                autoComplete={field[1].autoComplete}
                type={field[1].type}
              />
              <span className={styles.formError}>{field[1].error}</span>
            </div>
          ))}
          {loading ? (
            <div className={styles.loadingContainer}>
              <CircularProgress classes={{ circle: styles.loadingCircle }} />
            </div>
          ) : (
            <button className={styles.submitButton} type="submit" disabled={loading}>
              Enviar
            </button>
          )}
        </form>
      ) : (
        <div className={styles.thankYouContainer}>
          <p>
            Tu registro fue realizado con éxito.
            <br />
            Para que recibas notificaciones y material informativo adicional te
            recomendamos
          </p>
          <a
            href="https://chat.whatsapp.com/IkX2fsYX7Ab82dhdP2K5Se"
            className={styles.whatsAppButton}
            target="_blank"
            rel="noreferrer"
          >
            Unirte al grupo de WhatsApp
          </a>
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;
